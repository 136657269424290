<template>
  <div id="calendar-popup">
    <vs-popup class="calendar-popup" fullscreen title="Calendario" :active.sync="isCalendarPopupActive">
      <div class="vx-card no-scroll-content calendar-flex-card" style="box-shadow:none">
      
        <calendar-view
          ref="calendar"
          :displayPeriodUom="calendarView"
          :show-date="showDate"
          :events="simpleCalendarEvents"
          :eventTop="windowWidth <= 400 ? '2rem' : '3rem'"
          eventBorderHeight="0px"
          eventContentHeight="4.5rem"
          class="theme-default lg:w-5/6 md:w-4/5 sm:w-full w-full"
          @click-event="openEditEvent"
        >
          <div slot="header" class="mb-4">
            <div class="vx-row no-gutter">
              <!-- Month Name -->
              <div class="vx-col w-1/3 items-center sm:flex hidden">

              </div>

              <!-- Current Month -->
              <div
                class="vx-col sm:w-1/3 w-full sm:my-0 my-3 flex sm:justify-end justify-center order-last"
              >
                <div class="flex items-center">
                  <feather-icon
                    :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
                    @click="updateMonth(-1)"
                    svgClasses="w-5 h-5 m-1"
                    class="cursor-pointer bg-primary text-white rounded-full"
                  />

                  <span class="mx-3 text-xl font-medium whitespace-no-wrap">{{ showDate | month }}</span>

                  <feather-icon
                    :icon="$vs.rtl ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
                    @click="updateMonth(1)"
                    svgClasses="w-5 h-5 m-1"
                    class="cursor-pointer bg-primary text-white rounded-full"
                  />
                </div>
              </div>

              <div class="vx-col sm:w-1/3 w-full flex justify-center">
                <template v-for="(view, index) in calendarViewTypes">
                  <vs-button
                    v-if="calendarView === view.val"
                    :key="String(view.val) + 'filled'"
                    type="filled"
                    class="p-3 lg:px-8 sm:px-3"
                    :class="{'border-l-0 rounded-l-none': index, 'rounded-r-none': calendarViewTypes.length !== index+1}"
                    @click="calendarView = view.val"
                  >{{ view.label }}</vs-button>
                  <vs-button
                    v-else
                    :key="String(view.val) + 'border'"
                    type="border"
                    class="p-3 lg:px-8 sm:px-3"
                    :class="{'border-l-0 rounded-l-none': index, 'rounded-r-none': calendarViewTypes.length !== index+1}"
                    @click="calendarView = view.val"
                  >{{ view.label }}</vs-button>
                </template>
              </div>
            </div>

            <div class="vx-row sm:flex hidden mt-4">
              <div class="vx-col w-full flex">
                <!-- Labels -->
                <div class="flex flex-wrap sm:justify-start justify-center">
                  <div
                    v-for="(label, index) in calendarLabels"
                    :key="index"
                    class="flex items-center mr-4 mb-2"
                  >
                    <div
                      class="h-3 w-3 inline-block rounded-full mr-2"
                      :class="'bg-' + label.color"
                    ></div>
                    <span>{{ label.text }}</span>
                  </div>
                  <div class="flex items-center mr-4 mb-2">
                    <div class="h-3 w-3 inline-block rounded-full mr-2 bg-dark"></div>
                    <span>Solamente se muestra la siguiente fecha inmediata para cada proyecto ya que las fechas posteriores dependen de la actividad inmediata anterior. </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </calendar-view>

        <vs-list class="lg:w-1/6 md:w-1/5 sm:w-full w-full calendar-vs-list">
          <vs-list-header title="Vencimientos"></vs-list-header>
          <vs-list-header class="subheader-list" title="Te recomendamos archivar los proyectos en caso de que no vaya a proceder su crédito por cualquier razón, de esta manera ya no aparecerán en tu calendario o como fechas vencidas." ></vs-list-header>
          <vs-list-item
            v-for="(ex, index) in expiredDates"
            :key="index"
            :title="ex.title"
            :subtitle="'Fecha vencida: ' + regularDateFormat(ex.date)">

            <a @click="redirectToEventFromExpired(ex.pid)" class="expired-link" >Ver proyecto</a>
          </vs-list-item>
        </vs-list>

      </div>

      <!-- EDIT EVENT -->
      <vs-prompt
        style="z-index:54000"
        class="calendar-event-dialog"
        title="Información del evento"
        accept-text="Ir al proyecto"
        cancel-text="Cerrar"
        button-cancel="border"        
        @cancel="closeEventDialog"
        @accept="redirectToEventInfo"
        :active.sync="showEvent" >

        <p> <span class="bold">ID Proyecto: </span> {{projectInfoId}}</p>   
        <p> <span class="bold">Fecha del evento: </span> {{regularDateFormat(projectDate)}}</p>
        <p> <span class="bold">Descripción: </span>{{content}}</p>
      </vs-prompt>
    </vs-popup>
  </div>
</template>

<script>
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
import dateHelper from '@mixins/dateHelper';
require('vue-simple-calendar/static/css/default.css')

const tagsDefs = [
  { tag: 0, classname: 'event-success'},
  { tag: 1, classname: 'event-warning'},
  { tag: 2, classname: 'event-danger'},
]

const eventLabelsDefs = [
  { color: "success", text: "Las fechas en verde indican eventos ya sucedidos." },
  { color: "warning", text: "Las fechas en naranja indican fecha estimada o límite para que RedGirasol realice alguna acción." },
  { color: "danger", text: "Las fechas en rojo indican fecha estimada o límite para que el Proveedor o Solicitante realicen alguna acción." },
]

const greenEventsTitleDefs = [
  { sla: 0, title: "$ID: Solicitud a revisión" },
  { sla: 1, title: "$ID: Pre-aprobado" },
  { sla: 2, title: "$ID: Solicitud completa" },
  { sla: 3, title: "$ID: Aprobado" },
  { sla: 4, title: "$ID: Solicitante realizó pago inicial" },
  { sla: 5, title: "$ID: Publicado a fondeo" },
  { sla: 6, title: "$ID: Terminó fondeo" },
  { sla: 8, title: "$ID: Contratos firmados" },
  { sla: 9, title: "$ID: Pago a proveedor" },
  { sla: 10, title: "$ID: Evidencia completa" },
  { sla: 11, title: "$ID: Entregado" },
]

const slaTitleDefs = [
  { sla: 0, title: "" },
  { sla: 1, title: "$ID: Pre-aprobación o rechazo de la solicitud" },
  { sla: 2, title: "$ID: Completar solicitud" },
  { sla: 3, title: "$ID: Aprobación o rechazo de la solicitud" },
  { sla: 4, title: "$ID: Pago inicial del solicitante" },
  { sla: 5, title: "$ID: Inicio de fondeo" },
  { sla: 6, title: "$ID: Finalización de fondeo" },
  { sla: 8, title: "$ID: Entrega de contratos firmados" },
  { sla: 9, title: "$ID: Pago de RedGirasol al proveedor" },
  { sla: 10, title: "$ID: Entrega de evidencia de instalación" },
  { sla: 11, title: "$ID: Pago de RedGirasol al proveedor" },
]

const slaContentDefs = [
  { sla: 0, title: "" },
  { sla: 1, title: "En esta fecha límite, RedGirasol, debe pre-aprobar o rechazar la solicitud. También es probable que parte de la documentación no sea correcta y se te informe a través de la bitácora o en el detalle del proyecto la información que no este completa o cumpla con la pre-solcitud." },
  { sla: 2, title: "En esta fecha límite, debes completar toda la documentación de la solicitud para que posteriormente RedGirasol pueda aprobarla o rechazarla. En caso de que el Solicitante ya no este interesado en el crédito u algún otro factor, te sugerimos archivar el proyecto para que no te lleguen recordatorios o se vayan a vencer las fechas." },
  { sla: 3, title: "En esta fecha límite, RedGirasol, debe aprobar o rechazar la solicitud. También es probable que parte de la documentación no sea correcta y se te informe a través de la bitácora o en el detalle del proyecto la información que no este completa o cumpla con la solcitud." },
  { sla: 4, title: "En esta fecha límite, el solicitante de crédito (tu cliente), debe realizar el pago inicial. Puedes consultar la ficha de pago, para que puedas recordarle a tu cliente de dicho pago. Una vez que tu cliente haya realizado el pago, se publicará el proyecto para iniciar el fondeo" },
  { sla: 5, title: "En esta fecha límite, RedGirasol publicará el proyecto para iniciar el fondeo" },
  { sla: 6, title: "En esta fecha límite, la Red de Inversionistas culminarán el fondeo del proyecto, esto comunmente ocurren antes de esta fecha. Se te informará por correo cuando el fondeo haya terminado." },
  { sla: 8, title: "En esta fecha límite, debes obtener las firmas de todos los contratos y completar la documentación pendiente para que RedGirasol te realice el pago y avances con el proyecto." },
  { sla: 9, title: "En esta fecha límite, RedGirasol te realizará el pago para avanzar con el proyecto. En caso de que alguna documentación de contratos o documentación este incompleta o incorrecta se te informará vía correo de manera inmediata para no detener el avance." },
  { sla: 10, title: "En esta fecha límite, debes concluir el proyecto o etapa (en caso de que sea proyecto de varias etapas) proporcionando la evidencia solicitada." },
  { sla: 11, title: "En esta fecha límite, RedGirasol te realizará el pago. En caso de que tengamos dudas o la evidencia sea incorrecta se te notificará inmediatamente vía correo para no retrasar la realización del pago." },
]

export default {
  name: "calendar-popup",
  mixins: [dateHelper],
  components: {
    CalendarView,
    CalendarViewHeader
  },
  data() {
    return {
      loading: false,

      calendarView: "week",
      showDate: new Date(),
      simpleCalendarEvents: [],
      expiredDates: [],

      calendarViewTypes: [
        {
          label: 'Semana',
          val: 'week'
        },
        {
          label: 'Mes',
          val: 'month'
        },
      ],
      calendarLabels: eventLabelsDefs,
      showEvent: false,
      title: null,
      content: null,
      projectInfoId: null,
      projectDate: null,
    };
  },
  computed: {
    isCalendarPopupActive: {
      get() {
        return this.$store.state.isCalendarPopupActive;
      },
      set(val) {
        this.$store.commit("TOGGLE_IS_CALENDAR_POPUP_ACTIVE", val);
      }
    },
    activePromptShowEvent: {
      get(){
        return this.showEvent;
      },
      set(val){
        this.showEvent = val;
      }
    },
    windowWidth () {
      return this.$store.state.windowWidth
    }
  },
  watch: {
    isCalendarPopupActive(val){
      if(val){
        // obtener datos
        this.getCalendarInfo();
      }
    },
    loading : function(newVal, oldVal){
      if(newVal == true){
         this.$vs.loading();
      }
      else {
        this.$vs.loading.close();
      }
    }, 
  },
   methods: {
    updateMonth (val) {
      this.showDate = this.$refs.calendar.getIncrementedPeriod(val)
    },
    async getCalendarInfo(){
      this.loading = true;
      try {
        let response = await axios.get('/api/v1/supplier/getCalendarEvents');
        let allEvents = response.data.events;
        let expired = response.data.expired;
        this.simpleCalendarEvents = [];
        this.expiredDates = [];
        let pp = 1;

        allEvents.forEach(ev => {
          let nev = {
            sla: ev.sla,
            title: this.getTitleForEvent(ev),
            classes: this.getClassForTag(ev.tag),
            startDate: ev.date,
            endDate: ev.date,
            pid: ev.project_id,
            exid: ev.external_id,
            tag: ev.tag,
            id: pp,
          };
          this.simpleCalendarEvents.push(nev);
          pp += 1;
        });

        expired.sort(function(a, b) {
          var dateA = new Date(a.date), dateB = new Date(b.date);
          return dateB - dateA;
        });

        let cc = 1;
        expired.forEach(ex => {
          if(cc < 20){
            let nex = {
              title: this.getTitleForExpired(ex),
              date: ex.date,
              pid: ex.project_id,
            };
            this.expiredDates.push(nex);
          }
          cc +=1;
        });
      }
      catch (error) {
        this.failedOperationNotif();
      }
      this.loading = false;
    },
    getClassForTag(tag){
      return tagsDefs.find(f => f.tag == tag).classname;
    },
    getTitleForEvent(ev){
      let id = ev.external_id || ev.project_id;
      let tt = "";
      
      if(ev.tag == 0){
        tt = greenEventsTitleDefs.find(f => f.sla == ev.sla).title;
      }
      else{
        tt = slaTitleDefs.find(f => f.sla == ev.sla).title;
      }

      tt = tt.replace('$ID', id);
      return tt;
    },
    getTitleForExpired(ex){
      let id = ex.external_id || ex.project_id;
      let tt = slaTitleDefs.find(f => f.sla == ex.sla).title;
      tt = tt.replace('$ID', id);
      return tt;
    },
    openEditEvent (event) {
      const e = this.simpleCalendarEvents.find(f => f.id == event.id);
      this.id = e.id
      this.title = e.title
      this.projectDate = e.startDate
      this.projectInfoId = e.pid
      this.projectInfoExternal = e.exid
      this.content = e.tag == 0 ? e.title : slaContentDefs.find(f => f.sla == e.sla).title
      this.showEvent = true
    },    
    closeEventDialog(){
      this.showEvent = false;
    },
    async redirectToEventInfo(){
      this.showEvent = false;
      this.isCalendarPopupActive = false;
      try {
        await this.$router.replace({ name: 'detalleProyecto', params: { id: this.projectInfoId } });        
      } catch (error) {        
      }
    },
    async redirectToEventFromExpired(pid){
      this.showEvent = false;
      this.isCalendarPopupActive = false;
      try {
        await this.$router.replace({ name: 'detalleProyecto', params: { id: pid } });        
      } catch (error) {        
      }
    }    
  },
};
</script>