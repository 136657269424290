<!-- =========================================================================================
  File Name: TheNavbar.vue
  Description: Navbar component
  Component Name: TheNavbar
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="relative">

    <div class="vx-navbar-wrapper" :class="classObj">

      <vs-navbar class="vx-navbar navbar-custom navbar-skelton" :color="navbarColorLocal" :class="textColor">

        <!-- SM - OPEN SIDEBAR BUTTON -->
        <feather-icon class="sm:inline-flex xl:hidden cursor-pointer p-2" icon="MenuIcon" @click.stop="showSidebar" />
        <!-- <vx-tooltip text="Programa ImpactaMás" position="bottom" color="primary">
          <div v-if="SupplierStatus >= 4" @click="goToImpactaMas()"><vs-chip class="ml-3 mt-1 text-md bold" style="cursor:pointer;"> Nivel: {{SupplierLevel}} </vs-chip></div>
        </vx-tooltip> -->
        <vs-spacer />

        <div class="navbar-bookmarks flex items-center mr-5">
          <ul class="vx-navbar__starred-pages">
            <div class="flex cursor-move">
              <li class="starred-page v-step-13" v-for="page in starredPagesLimited" :key="page.id">
                <vx-tooltip :text="page.title" position="bottom" color="primary">
                  <feather-icon :svgClasses="['h-6 w-6 stroke-current', textColor]" class="p-2 cursor-pointer" :icon="page.icon" @click="execMethod(page.action)" />
                </vx-tooltip>
              </li>
            </div>
          </ul>
        </div>

        <!-- indicacion perfil -->
        <!--vs-chip 
          v-if="supplier_status_4_or_more"
          class="ml-4 mt-1 no-box-shadow"
          :color="supplier_profile_status_color"
          >{{ supplier_profile_status }}
        </vs-chip-->
        

        <notification-drop-down v-if="showNotifs"/>

        <profile-drop-down />

      </vs-navbar>
    </div>
  </div>
</template>


<script>
import NotificationDropDown from './components/NotificationDropDown.vue'
import ProfileDropDown      from './components/ProfileDropDown.vue'

const navIconsDefinitions = [
  { id: 0, title: "Calendario", icon: "CalendarIcon", action: "showCalendar", condition: "supplier_status_4_or_more", },
  // { id: 1, title: "Bitácora", icon: "MessageSquareIcon", action: "showGeneralLog" },
  { id: 2, title: "Dinos qué opinas", icon: "ThumbsUpIcon", action: "showFeedback" },
];

export default {
  name: 'the-navbar-vertical',
  data (){
    return {
      showNotifs: false,
    }
  },
  props: {
    navbarColor: {
      type: String,
      default: '#fff'
    }
  },
  components: {
    NotificationDropDown,
    ProfileDropDown
  },
  computed: {
    starredPagesLimited(){
      return navIconsDefinitions.filter(f => {
        if(f.condition != null){
          return (f.condition in this && this[f.condition] == true);
        }
        return true;
      })
    },
    supplier_status_4_or_more(){
      return this.SupplierStatus >= 4;
    },
    navbarColorLocal () {
      if(this.$store.state.theme === 'rg-semi-dark'){
        return "";
      }
      else {        
        return this.$store.state.theme === 'dark' && this.navbarColor === '#fff' ? '#10163a' : this.navbarColor
      }

    },
    verticalNavMenuWidth () {
      return this.$store.state.verticalNavMenuWidth
    },
    textColor () {
      return {'text-white': (this.navbarColor !== '#10163a' && this.$store.state.theme === 'dark') || (this.navbarColor !== '#fff' && this.$store.state.theme !== 'dark')}
    },
    windowWidth () {
      return this.$store.state.windowWidth
    },

    // NAVBAR STYLE
    classObj () {
      if      (this.verticalNavMenuWidth === 'default') return 'navbar-default'
      else if (this.verticalNavMenuWidth === 'reduced') return 'navbar-reduced'
      else if (this.verticalNavMenuWidth)               return 'navbar-full'
    },

    supplier_profile_status(){
      let ss = this.SupplierStatus;
      if(ss < 3){
        return "PENDIENTE";
      }
      else if(ss == 3){
        return "EN PROCESO";
      }
      else if(ss > 3){
        return "VERIFICADO"
      }
      else {
        return null;
      }
    },
    supplier_profile_status_color(){
      let ss = this.SupplierStatus;
      if(ss < 3){
        return 'danger';
      }
      else if(ss == 3){
        return 'warning';
      }
      else if(ss > 3){
        return 'success'
      }
      else {
        return null;
      }
    },
  },
  methods: {
    showSidebar () {
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
    },
    showCalendar(){
      this.$store.commit('TOGGLE_IS_CALENDAR_POPUP_ACTIVE', true)
    },
    showFeedback(){
      this.$store.commit('TOGGLE_IS_FEEDBACK_POPUP_ACTIVE', true)
    },
    showGeneralLog(){
      this.$store.commit('TOGGLE_IS_GENERAL_LOG_POPUP_ACTIVE', true)
    },
    execMethod(method){
      this[method]();
    },
    async goToImpactaMas(){
         await this.$router.push({name: 'impactaMas'});
      },
  }
}
</script>

<style scoped>
li {
    list-style: none;
}
</style>

